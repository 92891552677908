



















































import Vue from 'vue';

import Player from '@/components/header/Player.vue';

Vue.component('Player', Player);

export default Vue.extend({
  name: 'Profile',
  data(): unknown {
    return {
      playerItems: {
        nbWin: 0,
        nbLoss: 0,
      },
      playerHistory: [],
      user: {},
    };
  },

  methods: {
    countWinLose() {
      const nbWin = this.playerHistory.filter(ph => ph.winner.id === this.user.id).length;
      const nbLoss = this.playerHistory.filter(ph => ph.loser.id === this.user.id).length;
      this.playerItems.nbWin = nbWin;
      this.playerItems.nbLoss = nbLoss;
    },
    fetchUser() {
      this.$http.get('/user/'+ this.$route.params.id).then(response => {
        this.user = response.data;
        this.$http.get('/user/matches/'+ this.$route.params.id).then(response => {
          this.playerHistory = response.data;
          this.countWinLose();
          this.playerHistory.sort((a, b) => { // sort by date
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
        }).catch(() => {
          this.$toast.error('Error while fetching matches');
        });
      }).catch(() => {
          this.$toast.error('User not found');
          this.$router.push({ name: 'Main' });
      });
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => this.fetchUser(),
      { immediate: true }
    );
  },
});
